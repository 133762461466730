<template>
    <div>
        <md-toolbar class="md-primary mb-2 fixed-toolbar" md-elevation="1">
            <h3 class="md-title" style="flex: 1" v-if="override.name">{{override.name}}</h3>
            <h3 class="md-title" style="flex: 1" v-else>New increase or decrease</h3>
           
            <md-button class="md-primary md-raised" @click="add">Save</md-button>
        </md-toolbar>
        <div class="main-inner-wrapper">
            <CRow :gutters="true">
                <CCol sm="12" lg="5">
                    <md-card>
                        <md-card-header>
                            <div class="md-title">Info</div>
                        </md-card-header>

                        <md-card-content>
                            <CRow>
                                <CCol sm="12">
                                    <md-field :class="getValidationClass('companyProfile')">
                                        <md-select v-model="selectedData.companyProfile" name="company_profile" id="company-profile" placeholder="Company" >
                                            <md-option v-for="companyProfile in companyProfiles"  :value="companyProfile.id" v-bind:key="companyProfile.id">{{companyProfile.name}}</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.override.companyProfile.required">Company is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('name')">
                                        <label>Name</label>
                                        <md-input v-model="override.name"></md-input>
                                        <span class="md-error" v-if="!$v.override.name.required">Name is required</span>
                                    </md-field>
                                
                                    <md-field :class="getValidationClass('type')">
                                        <label for="type">Method</label>
                                        <md-select v-model="override.type" name="type" id="type" placeholder="Method">
                                            <md-option value="fixed">Fixed amount
                                            </md-option>
                                            <md-option value="percentage">Percentage
                                            </md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.override.type.required">Type is required</span>
                                    </md-field>
                                    
                                    <md-field :class="getValidationClass('value')">
                                        <span class="md-prefix" v-if="override.type == 'fixed'">$</span>
                                        <span class="md-prefix" v-if="override.type == 'percentage'">%</span>
                                        <label>Value</label>
                                        <md-input v-model="override.value" type="number"></md-input>
                                        <span class="md-error" v-if="!$v.override.value.required">Value is required</span>
                                    </md-field>
                                    
                                    <md-field>
                                        <md-select  v-model="override.vehicleTypes" name="vehicle_type" id="vehicle_type" placeholder="Vehicle Type" multiple>
                                            <md-option v-for="vehicleType in vehicleTypes" :value="vehicleType.id" v-bind:key="vehicleType.id">{{vehicleType.type}}</md-option>
                                        </md-select>
                                        <span class="md-helper-text">If none is selected will apply for all available vehicles</span>
                                    </md-field>
                                    <md-field>
                                        <md-select  v-model="override.serviceTypes" name="service_type" id="service_type" placeholder="Service Type" multiple>
                                            <md-option v-for="serviceType in serviceTypes" :value="serviceType.id" v-bind:key="serviceType.id">{{serviceType.name}}</md-option>
                                        </md-select>
                                        <span class="md-helper-text">If none is selected will apply for all available services</span>
                                    </md-field>
                                    <CRow>
                                        <CCol sm="4">
                                            <md-switch v-model="override.enabled" class="md-primary">Enabled</md-switch>
                                        </CCol> 
                                        <CCol sm="4">
                                            <md-switch v-model="override.geofenced" class="md-primary">Use Geofence</md-switch>
                                        </CCol>
                                    </CRow>
                                    <md-field v-if="override.geofenced" :class="getValidationClass('geofenceType')">
                                        <label :for="'geofence-type'">Geofence trigger type</label>
                                        <md-select v-model="override.geofenceType" :name="'geofence-type'" :id="'geofence-type'">
                                            <md-option value="pu" >On Pick Up</md-option>
                                            <md-option value="do" >On Drop Off</md-option>
                                            <md-option value="pu-do" >On Pick or Drop off</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.override.geofenceType.required">Geofence type is required</span>
                                    </md-field>
                                    <md-field v-if="override.geofenced && override.geofenceType" :class="getValidationClass('fence')">
                                        <label :for="'override-fence'">{{ override.geofenceType == 'pu' ? 'Pick up fence' : 'Drop off fence' }}</label>
                                        <md-select v-model="override.fence" :name="'override-fence'" :id="'override-fence'">
                                            <md-option v-for="geofence in geofences" :value="geofence.id" v-bind:key="geofence.id">{{geofence.code}} | {{geofence.name}}</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.override.fence.required">Value is required</span>
                                        <span class="md-helper-text">{{ override.geofenceType == 'pu' ? 'override will be applied if PU location is inside selected geofence' : 'override will be applied if DO location is inside selected geofence' }}</span>
                                    </md-field>

                                
                                    
                                </CCol>
                            </CRow>
                            
                        </md-card-content>
                    
                    </md-card>
                </CCol>
                <CCol sm="12" lg="7">
                    <md-card v-if="override.geofenced && selectedFence" class="mb-3">
                        <md-card-header>
                            <div class="md-title">Selected geofence</div>
                        </md-card-header>
                        <md-card-content>
                            <GmapMap :center="selectedFence.center" :zoom="selectedFence.zoom" style="width:100%;height:500px" ref="map">
                                <gmap-polygon :paths="selectedFence.path" :editable="true">
                                </gmap-polygon>
                            </GmapMap>
                        </md-card-content>
                    </md-card>
                    <md-card>
                        <md-card-header>
                            <div class="md-title">Scheduling config</div>
                            <p>If all fields are empty it will apply always</p>
                            <p v-if="override.companyProfile">Selected date and time below will be applied to <b>{{override.companyProfile.name}}</b> timezone <b>{{override.companyProfile.timeZone}}</b></p>
                            <div v-if="override.companyProfile"><b>Actual date time</b> {{companyActualDateTime}}</div>
                        </md-card-header>

                        <md-card-content>
                            <CRow>
                                <CCol sm="6">
                                    <md-field >
                                        <VueCtkDateTimePicker 
                                            v-model="override.startDatetime" 
                                            :dark="true" 
                                            :inline="false" 
                                            :formatted="'llll (HH:mm [HS])'"
                                            :format="'YYYY-MM-DD hh:mm a'"
                                            :output-format="'X'"
                                            :color="'#448aff'"
                                            :button-color="'#448aff'"	
                                            :minute-interval="5"
                                            id="ul-timepicker"
                                            :label="'Start Date time'"
                                        />
                                    </md-field>
                                </CCol>
                                
                                
                        
                                <CCol sm="6">
                                    <md-field>
                                        <VueCtkDateTimePicker 
                                            v-model="override.endDatetime" 
                                            :dark="true" 
                                            :inline="false" 
                                            :formatted="'llll (HH:mm [HS])'"
                                            :format="'YYYY-MM-DD hh:mm a'"
                                            :output-format="'X'"
                                            :color="'#448aff'"
                                            :button-color="'#448aff'"	
                                            :minute-interval="5"
                                            id="ul-timepicker"
                                            :label="'End Date time'"
                                        />
                                    </md-field>
                                </CCol>
                                
                                
                            </CRow>
                            <CRow>
                                <CCol sm="6">
                                    <md-field >
                                        <VueCtkDateTimePicker 
                                            v-model="override.startTime" 
                                            :dark="true" 
                                            :inline="false" 
                                            :formatted="'hh:mm a'"
                                            :format="'hh:mm a'"
                                            :output-format="'HH:mm'"
                                            :color="'#448aff'"
                                            :button-color="'#448aff'"	
                                            :minute-interval="5"
                                            id="ul-timepicker"
                                            :label="'Start time'"
                                            :only-time="true"
                                        />
                                    </md-field>
                                </CCol>
                                
                                
                        
                                <CCol sm="6">
                                    <md-field>
                                        <VueCtkDateTimePicker 
                                            v-model="override.endTime" 
                                            :dark="true" 
                                            :inline="false" 
                                            :formatted="'hh:mm a'"
                                            :format="'hh:mm a'"
                                            :output-format="'HH:mm'"
                                            :color="'#448aff'"
                                            :button-color="'#448aff'"	
                                            :minute-interval="5"
                                            id="ul-timepicker"
                                            :label="'End time'"
                                            :only-time="true"
                                        />
                                    </md-field>
                                </CCol>
                                
                                
                            </CRow>
                            <CRow>
                                <CCol sm="12">
                                    <md-field>
                                        <md-select  v-model="override.repeatEvery" name="repeat_every" id="repeat_every" placeholder="Apply every" multiple>
                                            <md-option v-for="day in days" :value="day.index" v-bind:key="day.index + day.name">Every {{day.name}}</md-option>
                                        </md-select>
                                        <span class="md-helper-text">If none is selected it will apply for the entire week</span>
                                    </md-field>
                                </CCol>
                            </CRow>

                        </md-card-content>
                    </md-card>
                </CCol>
            </CRow>
        </div>


        
        
    </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import firebase from '../../Firebase'
import { validationMixin } from 'vuelidate'
import {
    required,
    requiredIf,
    between,
    email,
    minLength,
    maxLength
} from 'vuelidate/lib/validators'
import moment from "moment";
import moment_timezone from "moment-timezone";
export default {
    name: 'NewOverride',
    mixins: [validationMixin],
    components: {
        VueTimepicker,
    },
    data() {
        return {
            
            companyActualDateTime:null,
            selectedData: {
                companyProfile: null,
            },
            
            days: [
                {
                    name: 'Sunday',
                    index: 0
                },
                {
                    name: 'Monday',
                    index: 1
                },
                {
                    name: 'Thursday',
                    index: 2
                },
                {
                    name: 'Wednesday',
                    index: 3
                },
                {
                    name: 'Thursday',
                    index: 4
                },
                {
                    name: 'Friday',
                    index: 5
                },
                {
                    name: 'Saturday',
                    index: 6
                },

            ],
            geofences: [],
            vehicleTypes: [],
            serviceTypes: [],
            isBusy: true,
            override: {
                name: null,
                code:null,
                startDatetime:null,
                endDatetime:null,
                convertedStartDateTime:null,
                convertedEndDateTime:null,
                startTime:null,
                endTime:null,
                repeatEvery: [],
                type: null,
                order:null,
                value:null,
                enabled:false,
                fence:null,
                geofenced:false,
                geofenceType:null,
                vehicleTypes: [],
                serviceTypes: [],
                companyProfile: null
            },
            companyProfiles: [] ,
            timezoneOffsetDif: null,
            selectedFence: null             
           
        }
    },
    
    methods: {

        updateTime() {
            var cd = moment(new Date()).tz(this.override.companyProfile.timeZone);
            this.companyActualDateTime = cd.format('dddd DD MMMM YYYY hh:mm a') ;
           
        },
        flowTimeZone() {
            console.log(this.override.companyProfile.timeZone);
            var tz = moment.tz.guess();
            console.log(tz);
            var dbaTZoffset = moment.tz(this.override.companyProfile.timeZone).utcOffset()
            console.log(dbaTZoffset);

            var creatorTZoffset = moment.tz(tz).utcOffset()
            console.log(creatorTZoffset);


            if(creatorTZoffset > dbaTZoffset) {
                // Ej USEastern (dba) -240  / BS AS (creator) -180 / 
                // Moscu 180 (dba) // nuevadelih (creator) 300

                this.timezoneOffsetDif = creatorTZoffset - dbaTZoffset ;

                // -180 -  -240 = 60
                // 300 - 180 = 120
            } else if(dbaTZoffset > creatorTZoffset) {

                // Ej USEastern(creator) -240  / BS AS (dba) -180 / 
                // Moscu 180 (creator) // nuevadelih (dba) 300
                this.timezoneOffsetDif = dbaTZoffset - creatorTZoffset ;
                // -240 - -180 = 
                // 180 - 300 = -120
            } else {
                this.timezoneOffsetDif = 0;
            }




        },
        async obtainViewResources() {
            this.isBusy = true;
            await this.getVehicleTypes()
            await this.getServiceTypes()
            await this.getGeofences()
            await this.getCompanyProfiles()
            this.isBusy = false;
        },
        add(){
             if(this.validate()) {
                let th = this;
                firebase.firestore().collection('users').doc(this.user.data.uid).collection('overrides')
                
                .add(th.override)
            
                .then(function(docRef) {
                    console.log("Tutorial created with ID: ", docRef.id);
                    th.$router.push({path: `/app/increases-and-decreases`})
                })
                .catch(function(error) {
                    console.error("Error adding Tutorial: ", error);
                });
             } else {
                 this.$v.override.$touch()
             }
        },
        validate(){
            let name,type,value,startDatetime,endDatetime,companyProfile;
            name = this.$v.override.name.$invalid;
            type = this.$v.override.type.$invalid;
            value = this.$v.override.value.$invalid;
            companyProfile = this.$v.override.companyProfile.$invalid;
            
           
            return !name && !type && !value && !startDatetime && !endDatetime && !companyProfile  ? true : false ;


        },
        getValidationClass (fieldName) {
            let field = null;

            
            field = this.$v.override[fieldName];
            
            
            
            if (field) {
                return {
                    'md-invalid': field.$invalid && field.$dirty
                }
            }
        },
        async getVehicleTypes() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(th.user.data.uid).collection('vehicle-types').orderBy("order").onSnapshot((querySnapshot) => {
                    th.vehicleTypes = [];
                    querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    th.vehicleTypes.push(dataToSave);
                    

                    });    
                    
                    resolve()
                    
                });
            })  
        },
        async getServiceTypes() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(th.user.data.uid).collection('service-types').orderBy("order").onSnapshot((querySnapshot) => {
                    th.serviceTypes = [];
                    querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    th.serviceTypes.push(dataToSave);
                    

                    });    
                    
                    resolve()
                    
                });
            })  
        },
        async getGeofences() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(this.user.data.uid).collection('geofences').onSnapshot((querySnapshot) => {
                    console.log('Snap shot')
                    this.geofences = [];
                    querySnapshot.forEach((doc) => {

                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        this.geofences.push(dataToSave);

                    });
                    resolve()
                });
            })  
        },
        async getCompanyProfiles() {
            let th = this;
            return new Promise((resolve, reject) => {
                this.$firebase.firestore().collection('users').doc(this.user.data.uid).collection('company-profiles').onSnapshot((querySnapshot) => {
                    this.companyProfiles = [];
                    querySnapshot.forEach((doc) => {
                        
                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        
                        this.companyProfiles.push(dataToSave);
                        
                    });
                    resolve();
                });


            })  
        },
         refreshGeofencePreview(fenceId) {
            for (let i = 0; i < this.geofences.length; i++) {
                
                if(this.geofences[i].id == fenceId) {
                    this.selectedFence = this.geofences[i];
                }
            }
        },
       
       
    },
    computed: {
      user() {
        return this.$store.state.user;
      }
       
    },
    watch: {
        'selectedData.companyProfile': {
            handler: function(val,oldVal) {
                console.log(val)
                for (let i = 0; i < this.companyProfiles.length; i++) {
                   if(this.companyProfiles[i].id === val) {
                        this.override.companyProfile = this.companyProfiles[i]
                        // this.override.startTime = moment(null).tz(this.override.companyProfile.timeZone)
                        // this.override.endTime = moment(null).tz(this.override.companyProfile.timeZone)
                        // this.override.startDatetime = moment(null).tz(this.override.companyProfile.timeZone)
                        // this.override.endDatetime = moment(null).tz(this.override.companyProfile.timeZone)
                        this.updateTime()
                        this.flowTimeZone();
                   }
                }
            }   
        },
        'override.startDatetime': {
            handler: function(val,oldVal) {
               
                let localDateTime = moment.unix(val) ;
                console.log(localDateTime);
                let utcRealDateTime = localDateTime.utcOffset(this.timezoneOffsetDif).format()
                console.log(utcRealDateTime);
                let utcCutOffset;
                if(this.timezoneOffsetDif === 0) {
                     utcCutOffset = utcRealDateTime
                } else {
                     utcCutOffset = utcRealDateTime.split('+')[0] + '+00:00'
                }
               
                console.log(utcCutOffset);

                let dbaDateTime = moment.utc(utcCutOffset);
                console.log(dbaDateTime.format('LLLL'));
                this.override.convertedStartDateTime = dbaDateTime.format('X');
            }

        },
        'override.endDatetime': {
            handler: function(val,oldVal) {
               
                 let localDateTime = moment.unix(val) ;
                 console.log(localDateTime);
                let utcRealDateTime = localDateTime.utcOffset(this.timezoneOffsetDif).format()
                console.log(utcRealDateTime);
                let utcCutOffset ;
                if(this.timezoneOffsetDif === 0) {
                     utcCutOffset = utcRealDateTime
                } else {
                     utcCutOffset = utcRealDateTime.split('+')[0] + '+00:00'
                }
                

                let dbaDateTime = moment.utc(utcCutOffset);
                console.log(dbaDateTime.format('LLLL'));
                this.override.convertedEndDateTime = dbaDateTime.format('X');

                
            }

        },
        'override.fence': {
            handler: function(val,oldVal) {
                this.refreshGeofencePreview(val)
            }
        }
        
    },
    created () {
       this.obtainViewResources()
       
    },
    
    validations: {
       
        override: {
            
            name: {
                required
            },
            
            type: {
                required
            },
            value: {
                required
            },
            // startDatetime: {
            //     required: requiredIf(function(override){
            //         return override.endDatetime ? true : false ;

            //     })
            // },
            // endDatetime: {
            //     required: requiredIf(function(override){
            //         return override.startDatetime ? true : false ;

            //     })
            // },
            fence: {
                required: requiredIf(function(override){
                    return override.geofenced ? true : false ;

                })
            },
            geofenceType: {
                required: requiredIf(function(override){
                    return override.geofenced ? true : false ;

                })
            },
            companyProfile: {
                required
            }
            
            
            
        }
    }
}
</script>  